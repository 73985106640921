<template>
  <div style="background-color: white">
    <Head ref="head"></Head>
    <div class="poundBody">
      <div class="poundBodyMoudle CommissionIsIntroduced" v-if="false">
        <div class="IntroducedfontBox">
          <h1>COMISIONES DE RAKUMART</h1>
          <h2>Comisiones justas, mucho valor</h2>
          <p>
            Aprovecha todas las ventajas que te ofrece Rakumart por muy poco.
            Comisiones ajustadas para ayudarte a crecer y sacar el máximo
            partido a tu negocio.
          </p>
        </div>
        <img
          class="IntroducedfontImg"
          src="../../assets/newImg/footlist/dengji/WechatIMG71.png"
          alt=""
        />
      </div>
      <!-- 手续费规则说明 -->
      <div class="poundBodyMoudle handlingFeeRule">
        <div class="caption">
          <h1>COMISIONES DE RAKUMART</h1>
          <h3>Aumenta el volumen de compra y paga menos comisión</h3>
          <p>
            Cobramos una comisión dependiendo del importe de los productos del
            <br />
            pedido. Se determinará la comisión dependiendo de la cantidad de
            transacciones que hayas generado.
          </p>
          <p>
            Por ejemplo, si tu volumen de compra en productos supera 40.000€, la tasa de
            comisión cambiará al 6% para los próximos pedidos. Consulta la
            siguiente tabla para ver qué porcentaje corresponde a tu compra:
          </p>
        </div>
        <div class="handlingFeeForm">
          <div class="tul FeeFormBody">
            <div class="tli FeeFormBody">
              <img
                src="../../assets/newImg/footlist/dengji/WechatIMG76.png"
                alt=""
              />
            </div>
            <div class="tli FeeFormBody baifenbi">6%</div>
            <div class="tli FeeFormBody jinqian">+40.000€</div>
          </div>
          <div class="tul FeeFormBody">
            <div class="tli FeeFormBody">
              <img
                src="../../assets/newImg/footlist/dengji/WechatIMG75.png"
                alt=""
              />
            </div>
            <div class="tli FeeFormBody baifenbi">7%</div>
            <div class="tli FeeFormBody jinqian">+30.000€</div>
          </div>
          <div class="tul FeeFormBody">
            <div class="tli FeeFormBody">
              <img
                src="../../assets/newImg/footlist/dengji/WechatIMG74.png"
                alt=""
              />
            </div>
            <div class="tli FeeFormBody baifenbi">8%</div>
            <div class="tli FeeFormBody jinqian">+20.000€</div>
          </div>
          <div class="tul FeeFormBody">
            <div class="tli FeeFormBody">
              <img
                src="../../assets/newImg/footlist/dengji/WechatIMG73.png"
                alt=""
              />
            </div>
            <div class="tli FeeFormBody baifenbi">9%</div>
            <div class="tli FeeFormBody jinqian">+10.000€</div>
          </div>
          <div class="tul FeeFormBody">
            <div class="tli FeeFormBody">
              <img
                src="../../assets/newImg/footlist/dengji/WechatIMG72.png"
                alt=""
              />
            </div>
            <div class="tli FeeFormBody baifenbi">10%</div>
            <div class="tli FeeFormBody jinqian">0€</div>
          </div>
        </div>
      </div>
      <!-- 佣金与价值 -->
      <div class="poundBodyMoudle commissionAndValue">
        <h3>Comisiones justas, mucho valor</h3>
        <p>
          Aprovecha todas las ventajas que te ofrece Rakumart por muy poco.
          Comisiones ajustadas para ayudarte a crecer y sacar el máximo partido
          a tu negocio.
        </p>
      </div>
      <!-- 手续费疑问 -->
      <div class="serviceFeeQuestion poundBodyMoudle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          style="enable-background: new 0 0 24 24"
          xml:space="preserve"
        >
          <path
            class="st0"
            fill="#94aff6"
            d="M11.4,1C6.6,1.3,3,5.5,3,10.3V17c0,1.7,1.3,3,3,3h1c1.1,0,2-0.9,2-2v-4c0-1.1-0.9-2-2-2H5v-1.7 C5,6.4,8,3.1,11.8,3c4-0.1,7.2,3.1,7.2,7v2h-2c-1.1,0-2,0.9-2,2v4c0,1.1,0.9,2,2,2h2v1h-6c-0.6,0-1,0.5-1,1s0.4,1,1,1h5 c1.7,0,3-1.3,3-3V10C21,4.8,16.6,0.7,11.4,1z"
          ></path>
        </svg>
        <div class="serviceFeeQuestionCon">
          <h3>¿Alguna duda? Contacta con un agente experto</h3>
          <p>
            ¿Estás buscando la mejor solución para tus importaciones desde
            China? Rakumart es la respuesta, nosotros nos encargamos de todo,
            desde la compra del producto hasta la recepción en la dirección de
            entrega. Contacta con un agente experto y empieza a beneficiarte de
            todas nuestras ventajas.
          </p>
          <button @click="openChat">Hablar con un agente</button>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>
<script>
import Head from "../../components/head/HomePageTop.vue";
import Foot from "../../components/foot/Foot";
export default {
  data() {
    return {};
  },
  components: {
    Head,
    Foot,
  },
  computed: {
    evn() {
      // || location.origin == 'http://localhost:8080'
      if (
        location.origin == "https://www.rakumart.es" ||
        location.origin == "https://rakumart.eu" ||
        location.origin == "www.rakumart.es" ||
        location.origin == "http://www.rakumart.es"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {},
  methods: {
    openChat() {
      this.$fun.clickJs();
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "../../css/mixin.scss";
.poundBody {
  padding-bottom: 50px;
  .poundBodyMoudle {
    width: 900px;
    margin: 0 auto;
    .caption {
      width: 631px;
      margin: 0 auto;
      padding: 10px;
    }
  }
  .CommissionIsIntroduced {
    width: 1100px;

    margin: 60px auto 80px;
    padding: 10px;
    height: 307.39px;
    border-radius: 6px;
    background: #1e2997;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .IntroducedfontBox {
      width: 525.97px;
      height: 287.39px;
      padding: 50px;
      color: white;
      h1 {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 18px;
        letter-spacing: 2px;
        color: #ffc100;
        margin-bottom: 20px;
      }
      h2 {
        font-size: 21px;
        font-weight: 500;
        line-height: 1.5em;
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.8em;
      }
    }
    .IntroducedfontImg {
      width: 451px;
      height: 361.54px;
      margin-right: 40px;
      transform: translateY(-10px);
    }
  }
  .handlingFeeRule {
    padding-top: 30px;
    > * {
      text-align: center;
    }
    h1 {
      color: #1a289d;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1.5em;
      letter-spacing: 2px;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 21px;
      font-weight: 500;
      line-height: 1.5em;
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.8em;
      margin-bottom: 0.9rem;
    }
    .handlingFeeForm {
      padding-top: 50px;
      .FeeFormTitle {
      }
      .FeeFormBody {
        .vipIcon {
          border: #1a289d solid 5px;
          width: 53px;
          height: 53px;
          font-size: 18px;
          font-weight: 500;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
        }
      }
      .tul {
        display: flex;
        align-items: center;
        border-radius: 6px;
        &.FeeFormTitle {
          background: #f0f3f6;
        }
        &.FeeFormBody {
          &:nth-child(2n + 1) {
            background-color: #f9f9fb;
          }
        }
        .tli {
          flex: 1;
          &.FeeFormTitle {
            background: #f0f3f6;
            height: 53px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #7d7f8f;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 2px;
          }
          &.FeeFormBody {
            height: 75px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 53px;
              height: 53px;
            }
            &.baifenbi {
              font-size: 24px;
              font-weight: 400;
              line-height: 14px;
            }
            &.jinqian {
              color: #808191;
              font-size: 21px;
              font-weight: 400;
              line-height: 14px;
            }
          }
        }
      }
    }
  }
  .commissionAndValue {
    text-align: center;
    margin-top: 50px;
    padding: 10px;
    h3 {
      color: var(--e-global-color-1792e67);
      font-family: "Open Sans", Sans-serif;
      font-size: 21px;
      font-weight: 500;
      line-height: 1.5em;
      margin-bottom: 20px;
    }
    p {
      width: 611px;
      margin: 0 auto 14.4px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.8em;
    }
  }
  .serviceFeeQuestion {
    width: 1200px;
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 70px 160px;
    background-color: #f8f9fa;
    svg {
      width: 40px;
      height: 40px;
      display: block;
      margin: 0 auto;
    }
    .serviceFeeQuestionCon {
      padding: 0 10px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 655px;
      margin: 0 auto;
      > h3 {
        font-size: 21px;
        font-weight: 500;
        line-height: 1.5em;
        margin-bottom: 10px;
      }
      > p {
        font-family: "Open Sans", Sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.8em;
        margin-bottom: 34.4px;
        text-align: center;
      }
      > button {
        font-size: 15px;
        line-height: 15px;
        font-weight: 600;
        padding: 20px 80px 20px 80px;
        border-radius: 4px;
        border: #1a73e8 solid 2px;
        color: #1a73e8;
        &:hover {
          color: #ffffff;
          background-color: #1a73e8;
          border-color: #1a73e8;
        }
      }
    }
  }
}
</style>
