<template>
  <div class="option">
    <Head style="margin-bottom: -20px" ref="head" />
    <div class="main">
      <div class="logoImgBox">
        <div class="imgBoxCon">
          <span> SERVICIOS ADICIONALES</span>
          <h1>
            Solución integral de servicios adicionales para toda de cadena de
            suministro
          </h1>
          <p>
            En Rakumart también disfrutarás de servicios adicionales como el
            etiquetado FBA, la fotografía de producto, la personalización ODM,
            traducción, etc. ¿Tienes tu propia marca privada? Perfecto,
            ¡contacta con nosotros!
          </p>
        </div>
        <div class="imgBoxCon"></div>
      </div>
      <!-- Servicios de producción de marca privada -->
      <div class="Services moudleP">
        <div class="ServicesH1">Servicios de producción de marca privada</div>
        <div class="ServicesCon" style="padding-top: 30px">
          <div class="ServicesConImg">
            <img
              src="../../assets/newImg/footlist/fuJiaFuWu/produccion-oem-rakumart-1.jpg"
              alt=""
            />
          </div>
          <div class="ServicesConSpan">
            <div class="ConSpanH1">Servicio de producción OEM</div>
            <div class="spanBox" style="border-color: #1a71e8">
              El cliente elabora un anteproyecto y encargamos a la planta de
              producción que lo produzca, obteniendo así un producto
              completamente original.
            </div>
            <div class="spanBox" style="border-color: #ffa200">
              Dado que el proceso comienza con la compra de materiales por parte
              de la fábrica, el volumen de producción inevitablemente aumenta.
            </div>
            <div class="spanBox" style="border-color: #47bd9b">
              Contactaremos con el fabricante para conocer el volumen de
              producción, el precio unitario, la fecha de entrega y la
              producción de muestras en la planta de producción.
            </div>
            <div class="btnBox">
              <button @click="openChat">
                Solicitar más información con un agente
              </button>
            </div>
          </div>
        </div>
        <div class="ServicesCon">
          <div class="ServicesConSpan">
            <div class="ConSpanH1">Servicio de producción ODM</div>
            <div class="spanBox" style="border-color: #1a71e8">
              Personalización sobre un producto existente agregando las
              funcionalidades o características deseadas por el cliente para
              distribuirlo bajo su marca privada.
            </div>
            <div class="spanBox" style="border-color: #ffa200">
              Este proceso como el anterior, comienza con la compra de
              materiales por parte de la fábrica, por lo tanto el volumen de
              producción aumenta.
            </div>
            <div class="spanBox" style="border-color: #47bd9b">
              Contactaremos con el fabricante para conocer el volumen de
              producción, el precio unitario, la fecha de entrega y la
              producción de muestras en la planta de producción.
            </div>
            <div class="btnBox">
              <button @click="openChat">
                Solicitar más información con un agente
              </button>
            </div>
          </div>
          <div class="ServicesConImg">
            <img
              src="../../assets/newImg/footlist/fuJiaFuWu/produccion-odm-rakumart-1.jpg"
              alt=""
            />
          </div>
        </div>
        <div class="ServicesCon">
          <div class="ServicesConImg">
            <img
              src="../../assets/newImg/footlist/fuJiaFuWu/produccion-packaging-personalizado-rakumart-2.jpg"
              alt=""
            />
          </div>
          <div class="ServicesConSpan">
            <div class="ConSpanH1">Packaging, etiquetas y folletos</div>
            <div class="spanBox" style="border-color: #1a71e8">
              Producción de packaging personalizado, etiquetas, paquetes,
              material informativo y elementos distintivos para tu marca
              privada.
            </div>
            <div class="spanBox" style="border-color: #ffa200">
              La producción depende del tamaño, color y del tipo de material
              requerido. Deberás proporcionar todos los elementos e información
              de tu marca para poder realizar un presupuesto.
            </div>
            <div class="spanBox" style="border-color: #47bd9b">
              Consulta con nuestros agentes los costes de montaje y adaptación
              de los elementos personalizados en el conjunto de productos.
            </div>
            <div class="btnBox">
              <button @click="openChat">
                Solicitar más información con un agente
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Servicios adicionales para tus productos -->
      <div class="productos moudleP">
        <div class="productosH1">Servicios adicionales para tus productos</div>
        <div class="productosOpt">
          <div class="productosOptImg">
            <img
              src="../../assets/newImg/footlist/fuJiaFuWu/servicio-adicional-de-fotografia-rakumart-2-300x300.png"
              alt=""
            />
          </div>
          <div class="productosOptCon">
            <h1>Fotografía de producto</h1>
            <p>
              Fotografía profesional para venta sin importar el tipo de artículo
              o complejidad.
            </p>
          </div>
          <div class="productosOptPrice">
            <div class="pri">1.04€ (7￥)/u</div>
            <button @click="openChat">Cotizar con un agente</button>
          </div>
        </div>
        <div class="productosOpt">
          <div class="productosOptImg">
            <img
              src="../../assets/newImg/footlist/fuJiaFuWu/servicio-adicional-etiquetado-amazon-fba-rakumart-300x300.png"
              alt=""
            />
          </div>
          <div class="productosOptCon">
            <h1>Etiquetado SKU</h1>
            <p>Colocación de etiquetas con código SKU</p>
          </div>
          <div class="productosOptPrice">
            <div class="pri">0.15€ (1￥)/u</div>
            <button @click="openChat">Cotizar con un agente</button>
          </div>
        </div>
        <div class="productosOpt">
          <div class="productosOptImg">
            <img src="../../assets/newImg/footlist/fuJiaFuWu/i3.png" alt="" />
          </div>
          <div class="productosOptCon">
            <h1>Etiquetado con código de barras</h1>
            <p>Incluye cambio de la bolsa de plástico, PP</p>
          </div>
          <div class="productosOptPrice">
            <div class="pri">0.15€ (1￥)/u</div>
            <button @click="openChat">Cotizar con un agente</button>
          </div>
        </div>
        <div class="productosOpt">
          <div class="productosOptImg">
            <img src="../../assets/newImg/footlist/fuJiaFuWu/i4.png" alt="" />
          </div>
          <div class="productosOptCon">
            <h1>Cambio bolsa de plástico, PP</h1>
            <p>Los artículos voluminosos, se cotizan por separado</p>
          </div>
          <div class="productosOptPrice">
            <div class="pri">0.08€ (0.5￥)/u</div>
            <button @click="openChat">Cotizar con un agente</button>
          </div>
        </div>
        <div class="productosOpt">
          <div class="productosOptImg">
            <img src="../../assets/newImg/footlist/fuJiaFuWu/i5.png" alt="" />
          </div>
          <div class="productosOptCon">
            <h1>Hoja informativa impresa doble cara</h1>
            <p>
              Por defecto es papel DIN A4, otros tipos de papel o impresión de
              folletos están disponibles bajo petición.
            </p>
          </div>
          <div class="productosOptPrice">
            <div class="pri">0.3€ (2￥)/por hoja</div>
            <button @click="openChat">Cotizar con un agente</button>
          </div>
        </div>
        <div class="productosOpt">
          <div class="productosOptImg">
            <img src="../../assets/newImg/footlist/fuJiaFuWu/i6.png" alt="" />
          </div>
          <div class="productosOptCon">
            <h1>Cosido de etiqueta de tela</h1>
            <p>
              Cosido de etiquetas personalizadas con tu marca en productos
              textiles como camisetas, camisas, etc.
            </p>
          </div>
          <div class="productosOptPrice">
            <div class="pri">0.15€ (1￥)/u</div>
            <button @click="openChat">Cotizar con un agente</button>
          </div>
        </div>
        <div class="productosOpt">
          <div class="productosOptImg">
            <img src="../../assets/newImg/footlist/fuJiaFuWu/i7.png" alt="" />
          </div>
          <div class="productosOptCon">
            <h1>Quitar etiquetas de tela</h1>
            <p>
              Quitamos las etiquetas de tela que puedan traer de fábrica algunos
              productos textiles.
            </p>
          </div>
          <div class="productosOptPrice">
            <div class="pri">0.23€ (1.5￥)/u</div>
            <button @click="openChat">Cotizar con un agente</button>
          </div>
        </div>
        <div class="productosOpt">
          <div class="productosOptImg">
            <img src="../../assets/newImg/footlist/fuJiaFuWu/i8.png" alt="" />
          </div>
          <div class="productosOptCon">
            <h1>Colocar etiquetas colgantes</h1>
            <p>
              Colocación de etiquetas colgantes con tu marca en los productos
              que requieras.
            </p>
          </div>
          <div class="productosOptPrice">
            <div class="pri">0.15€ (1￥)/u</div>
            <button @click="openChat">Cotizar con un agente</button>
          </div>
        </div>
        <div class="productosOpt">
          <div class="productosOptImg">
            <img src="../../assets/newImg/footlist/fuJiaFuWu/i9.png" alt="" />
          </div>
          <div class="productosOptCon">
            <h1>Quitar etiquetas colgantes</h1>
            <p>
              Quitar etiquetas colgantes de fábrica de los productos que
              requieras.
            </p>
          </div>
          <div class="productosOptPrice">
            <div class="pri">0.15€ (1￥)/u</div>
            <button @click="openChat">Cotizar con un agente</button>
          </div>
        </div>
        <div class="productosOpt">
          <div class="productosOptImg">
            <img src="../../assets/newImg/footlist/fuJiaFuWu/i10.png" alt="" />
          </div>
          <div class="productosOptCon">
            <h1>Empaquetado conjunto</h1>
            <p>
              Los gastos de manipulación para más de 4 artículos se cotizan por
              separado
            </p>
          </div>
          <div class="productosOptPrice">
            <div class="pri">0.15€ (1￥)/u</div>
            <button @click="openChat">Cotizar con un agente</button>
          </div>
        </div>
        <div class="productosOpt">
          <div class="productosOptImg">
            <img src="../../assets/newImg/footlist/fuJiaFuWu/i11.png" alt="" />
          </div>
          <div class="productosOptCon">
            <h1>Proteger con bolsa de burbujas, PP</h1>
            <p>Los artículos voluminosos, se cotizan por separado</p>
          </div>
          <div class="productosOptPrice">
            <div class="pri">0.15€ (1￥)/u</div>
            <button @click="openChat">Cotizar con un agente</button>
          </div>
        </div>
      </div>
      <div class="expertAgent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          style="enable-background: new 0 0 24 24"
          xml:space="preserve"
        >
          <path
            class="st0"
            d="M11.4,1C6.6,1.3,3,5.5,3,10.3V17c0,1.7,1.3,3,3,3h1c1.1,0,2-0.9,2-2v-4c0-1.1-0.9-2-2-2H5v-1.7 C5,6.4,8,3.1,11.8,3c4-0.1,7.2,3.1,7.2,7v2h-2c-1.1,0-2,0.9-2,2v4c0,1.1,0.9,2,2,2h2v1h-6c-0.6,0-1,0.5-1,1s0.4,1,1,1h5 c1.7,0,3-1.3,3-3V10C21,4.8,16.6,0.7,11.4,1z"
          ></path>
        </svg>
        <div class="expertAgentCon">
          <h1>¿Requieres servicios especiales en tus productos?</h1>
          <p>
            Dinos qué servicios requieres y cuáles son los productos para que
            podamos informarte sobre todas las posibilidades.
          </p>
          <button @click="openChat">Chatear con un agente</button>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>
<script>
import Head from "../../components/head/HomePageTop.vue";
import Foot from "../../components/foot/Foot";
export default {
  data() {
    return {};
  },
  components: {
    Head,
    Foot,
  },
  methods: {
    openChat() {
      this.$fun.clickJs();
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  min-width: 1400px;
  background-color: white;
  padding-bottom: 1px;
  .moudleP {
    width: 1200px;
    margin: 0 auto;
    padding: 0 100px;
  }
  .logoImgBox {
    background-image: url("../../assets/newImg/footlist/fuJiaFuWu/servicios-adicionales-rakumart-scaled.jpg");
    background-position: 50% 50%;
    background-size: 100% auto;
    height: 466.78px;
    display: flex;
    justify-content: center;
    align-items: center;
    .imgBoxCon {
      display: flex;
      width: 595px;
      padding: 10px;
      justify-content: center;
      flex-direction: column;
      span {
        color: #ffad00;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.5em;
        letter-spacing: 2px;
        margin-bottom: 32px;
        display: block;
      }
      h1 {
        color: #ffffff;
        font-size: 26px;
        font-weight: 600;
        line-height: 1.5em;
        margin-bottom: 20px;
      }
      p {
        text-align: left;
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.8em;
      }
    }
  }
  .Services {
    .ServicesH1 {
      margin-top: 80px;
      line-height: 62px;
      font-size: 28px;
      font-weight: 400;
      text-align: center;
    }
    .ServicesCon {
      padding-top: 50px;
      padding-bottom: 80px;
      display: flex;
      justify-content: space-between;
      .ServicesConImg {
        width: 455px;
        height: 552.81px;

        img {
          width: 100%;
          border-radius: 15px;
          overflow: hidden;
          height: 100%;
        }
      }
      .ServicesConSpan {
        width: 475.44px;
        padding: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .ConSpanH1 {
          font-size: 21px;
          font-weight: 400;
          line-height: 31px;
          margin-bottom: 30px;
        }
        .spanBox {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 20px;
          line-height: 1.6em;
          border-left: solid 2px;
          padding-left: 20px;
        }
        .btnBox {
          padding-top: 20px;
          button {
            transition: 0.3s;
            border-style: solid;
            border-width: 2px 2px 2px 2px;
            border-color: #1a73e8;
            color: #1a73e8;
            border-radius: 60px 60px 60px 60px;
            padding: 15px 50px 15px 50px;
            font-weight: 500;
            &:hover {
              color: #ffffff;
              background-color: #1a73e8;
              border-color: #1a73e8;
            }
          }
        }
      }
    }
  }
  .productos {
    .productosH1 {
      text-align: center;
      margin-top: 30px;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5;
      color: #333;
      background-color: #fff;
    }
    .productosOpt {
      width: 820px;
      padding: 15px;
      margin: 30px auto;
      border-radius: 6px;
      border: #e3e3e3 solid 1px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: center;
      align-items: center;
      .productosOptImg {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        box-sizing: content-box;
        margin-right: 12px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .productosOptCon {
        padding: 10px;
        flex: 1;
        h1 {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          margin-bottom: 5px;
        }
        p {
          margin-bottom: 14.4px;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .productosOptPrice {
        width: 272.88px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .pri {
          text-align: center;
          color: #656565;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 14.4px;
        }
        button {
          border-style: solid;
          border-width: 1px 1px 1px 1px;
          border-color: #cecece;
          border-radius: 4px 4px 4px 4px;
          padding: 12px 30px 12px 30px;
          line-height: 14px;
          transition: 0.3s;
          color: #1a73e8;
          font-weight: 500;
          &:hover {
            color: #1a73e8;
            background-color: #f3f7fc;
            border-color: #1a73e8;
          }
        }
      }
    }
  }
  .expertAgent {
    // height: 378.7px;
    width: 1200px;
    margin: 0 auto;
    background-color: #f8f9fa;
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 70px;
    .st0 {
      fill: #94aff6;
    }
    svg {
      width: 40px;
      height: 40px;
      display: block;
      margin: 0 auto 5.71px;
    }
    .expertAgentCon {
      width: 635px;
      padding: 10px;
      margin: 0 auto;
      > * {
        text-align: center;
      }
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      h1 {
        font-size: 21px;
        font-weight: 500;
        line-height: 1.5em;
        margin-bottom: 5px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.8em;
        margin-bottom: 20px;
      }
      button {
        font-size: 15px;
        line-height: 15px;
        font-weight: 600;
        height: 60px;
        padding: 20px 80px 20px 80px;
        border-radius: 4px;
        color: #1a73e8;
        border: #1a73e8 solid 2px;
        &:hover {
          color: #ffffff;
          background-color: #1a73e8;
          border-color: #1a73e8;
        }
      }
    }
  }
}
</style>
