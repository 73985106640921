<template>
  <div style="background: #f6f6f8">
    <HomePageTopVue ref="head"></HomePageTopVue>
    <div class="LogisticsCon">
      <!-- logo图标与文字 -->
      <div class="logoImg">
        <div class="base-w">
          <div class="logoInfoTxtBox">
            <h1>GESTIONAMOS TODO POR TI</h1>
            <h2>Logística y envío con Rakumart</h2>
            <h3>
              Rakumart gestiona y realiza por ti todo el proceso de logística e
              importación <br />
              desde China. Aprovecha todos los recursos que Rakumart pone a tu
              <br />
              disposición para cambiar por completo la manera de comprar e
              importar <br />
              desde China, ahorra tiempo y costes para tu negocio.
            </h3>
          </div>
        </div>
      </div>

      <!-- 我们的优势 -->
      <div class="moudlebackGround">
        <div class="ourAdvantages pageMoudle">
          <div class="AdvantagesTitle">
            <span>Nuestras ventajas</span>
            <div class="xian"></div>
          </div>
          <div class="AdvantagesTagGroup">
            <div class="AdvantagesTag">
              <img
                class="tagLeftIcon"
                src="../../assets/newImg/footlist/yunshu/WechatIMG55.png"
                alt=""
              />
              <div class="tagRightCon">
                <div class="tagRightConTitle">Envío puerta a puerta</div>
                <div class="tagRightConTBody">
                  Rakumart importa por ti y gestiona todo el proceso de envío y
                  trámites hasta la entrega.
                </div>
              </div>
            </div>
            <div class="AdvantagesTag">
              <img
                class="tagLeftIcon"
                src="../../assets/newImg/footlist/yunshu/WechatIMG57.png"
                alt=""
              />
              <div class="tagRightCon">
                <div class="tagRightConTitle">Optimización de costes</div>
                <div class="tagRightConTBody">
                  Agrupamos mercancías de distintos proveedores y reunificamos
                  envíos internacionales.
                </div>
              </div>
            </div>
            <div class="AdvantagesTag">
              <img
                class="tagLeftIcon"
                src="../../assets/newImg/footlist/yunshu/WechatIMG52.png"
                alt=""
              />
              <div class="tagRightCon">
                <div class="tagRightConTitle">Almacenamiento en China</div>
                <div class="tagRightConTBody">
                  Contamos con almacenes propios en China para la gestión
                  logística de todas las mercancías.
                </div>
              </div>
            </div>
            <div class="AdvantagesTag">
              <img
                class="tagLeftIcon"
                src="../../assets/newImg/footlist/yunshu/WechatIMG54.png"
                alt=""
              />
              <div class="tagRightCon">
                <div class="tagRightConTitle">Control de calidad</div>
                <div class="tagRightConTBody">
                  Nos ocupamos de realizar los controles de calidad pertinentes
                  cuando recibimos las mercancías.
                </div>
              </div>
            </div>
            <div class="AdvantagesTag">
              <img
                class="tagLeftIcon"
                src="../../assets/newImg/footlist/yunshu/WechatIMG58.png"
                alt=""
              />
              <div class="tagRightCon">
                <div class="tagRightConTitle">Servicio personalizado</div>
                <div class="tagRightConTBody">
                  Podemos adaptarnos a cualquier necesidad de nuestros clientes
                  que precisen servicios a medida.
                </div>
              </div>
            </div>
            <div class="AdvantagesTag">
              <img
                class="tagLeftIcon"
                src="../../assets/newImg/footlist/yunshu/WechatIMG53.png"
                alt=""
              />
              <div class="tagRightCon">
                <div class="tagRightConTitle">Atención al cliente</div>
                <div class="tagRightConTBody">
                  Con agentes dedicados en cada paso del proceso, desde China y
                  en idioma español.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 物流过程 -->
      <div class="moudlebackGround">
        <div class="logisticsProcess pageMoudle">
          <div class="AdvantagesTitle">
            <span> Proceso logístico</span>
            <div class="xian"></div>
          </div>
          <div class="logisticsProcessOPtBox">
            <img
              class="logisticsProcessOptImg"
              src="../../assets/foot-img/logistica/linea-temporal-rakumart-logistica.svg"
              alt=""
            />
            <div class="logisticsProcessOPtArr">
              <div class="logisticsProcessOPt">Fábrica</div>
              <div class="logisticsProcessOPt">Transporte</div>
              <div class="logisticsProcessOPt">Almacén China</div>
              <div class="logisticsProcessOPt">Aduana China</div>
              <div class="logisticsProcessOPt">Envío</div>
              <div class="logisticsProcessOPt">Aduana España</div>
              <div class="logisticsProcessOPt">Almacén España</div>
              <div class="logisticsProcessOPt">Envío nacional</div>
            </div>
          </div>
        </div>
      </div>
      <div class="moudlebackGround">
        <!-- 我们将全程陪伴 -->
        <div class="companyLabor pageMoudle">
          <img
            class="LaborBackImg"
            src="../../assets/newImg/footlist/yunshu/WechatIMG69.png"
            alt=""
          />
          <div class="LaborFontBox">
            <div class="LaborLinkFont">LOGÍSTICA COMPLETA DESDE FÁBRICA</div>
            <div class="LaborFontTitle">
              Te acompañamos durante todo el proceso, <br />
              desde la compra hasta tu puerta
            </div>
            <div class="LaborFontCon">
              Rakumart ofrece la gestión completa del servicio logístico que va
              <br />
              desde la fábrica del proveedor hasta tu dirección de entrega.
            </div>
            <div class="LaborFontCon">
              Estos permite que nuestros clientes puedan saber en todo momento
              <br />
              en que paso del proceso de importación se encuentra su mercancía.
            </div>
            <div class="LaborFontCon">
              Contamos con más de 70.000 m2 de almacenes en China en los que
              recibimos, manipulamos y almacenamos hasta su salida a España.
            </div>
          </div>
        </div>
      </div>
      <!-- 运输方式表格 -->
      <div class="moudlebackGround">
        <div class="modeOfTransportationTable pageMoudle">
          <div class="modeOfTransportation">
            <div class="tul ttt">
              <div class="inBox">
                <div class="tli ttt titleBox">TRANSPORTE</div>
                <div class="tli ttt conBox">VOLUMEN MÍNIMO</div>
                <div class="tli ttt conBox">VOLUMEN MÁXIMO</div>
                <div class="tli ttt conBox">TIEMPO DE TRANSPORTE*</div>
              </div>
            </div>
            <div class="tul tbo">
              <div class="inBox">
                <div class="tli tbo titleBox">Aéreo</div>
                <div class="tli tbo conBox">Sin mínimo</div>
                <div class="tli tbo conBox">Sin máximo</div>
                <div class="tli tbo conBox">10-20 Días aproximadamente</div>
              </div>
            </div>
            <div class="tul tbo">
              <div class="inBox">
                <div class="tli tbo titleBox">Terrestre</div>
                <div class="tli tbo conBox">13kg</div>
                <div class="tli tbo conBox">Sin máximo</div>
                <div class="tli tbo conBox">35-45 Días aproximadamente</div>
              </div>
            </div>
            <div class="tul tbo">
              <div class="inBox">
                <div class="tli tbo titleBox">Marítimo</div>
                <div class="tli tbo conBox">10m3</div>
                <div class="tli tbo conBox">Sin máximo</div>
                <div class="tli tbo conBox">60-70 Días aproximadamente</div>
              </div>
            </div>
            <div class="tul tbo">
              <div class="inBox">
                <div class="tli tbo titleBox">Ferroviario</div>
                <div class="tli tbo conBox">21kg</div>
                <div class="tli tbo conBox">Sin máximo</div>
                <div class="tli tbo conBox">45-55 Días aproximadamente</div>
              </div>
            </div>
          </div>
          <!-- 物流方式说明 -->
          <div class="logisticsMethodDescription">
            *Los tiempos de transporte hacen referencia específicamente al
            periodo requerido por la compañía logística para transportar la
            mercancía desde su punto de origen hasta su punto de destino. Por lo
            tanto, el «tiempo de transporte» no debe confundirse con el «tiempo
            de entrega total». Estos tiempos son aproximados y pueden variar
            dependiendo de factores externos como el clima, regulaciones
            aduaneras, entre otros. Puedes consultar más información sobre
            logística y envíos haciendo
            <a href="/logistica-y-envio" target="_blank">clic aquí</a>.
          </div>
        </div>
      </div>
      <!-- 联系我们 -->
      <div class="lianXiWoMen">
        <div class="lianXibox">
          <h1>¿Necesitas cotizar el envío de mercancía?</h1>
          <div class="lianXiboxCon">
            <img
              class="boxIcon"
              src="../../assets/newImg/footlist/headphone-rounded.png"
              alt=""
            />
            <h2>Ponte en contacto con un agente experto</h2>
            <p>
              Sigue los pasos de compra o danos la información sobre la
              mercancía que deseas enviar para que podamos ayudarte.
            </p>
            <button @click="openChat">Cotizar con un agente</button>
          </div>
        </div>
      </div>
      <!-- 物流竞争优势 -->
      <div class="logisticsYouShi">
        <img
          src="../../assets/newImg/footlist/yunshu/logistica-rakumart.jpg"
          class="logisticsYouShiLeft"
          alt=""
        />
        <div class="logisticsYouShiRight">
          <div>
            <div class="YouShiRightTitle">
              Nuestra capacidad logística en China crea una ventaja
              <br />competitiva de la que nuestros clientes se benefician<br />
              por su comodidad y ahorro
            </div>
            <div class="YouShiRightCon">
              Soporte 24/7 en todas las etapas de entrega
            </div>
            <div class="YouShiRightCon">
              Disfruta de tarifas de envío e importación optimizadas
            </div>
            <div class="YouShiRightCon">
              Almacenamiento, manipulación y control de calidad incluido
            </div>
            <div class="YouShiRightCon">
              Seguimiento de la importación desde tu Panel de control
            </div>
          </div>
        </div>
      </div>
      <!-- 物流中心 -->
      <div class="moudlebackGround">
        <div class="logisticsCenter pageMoudle">
          <div class="logisticsCenterOpt">
            <div class="CenterOptIcon" />
            <div class="CenterOptTt">Centros logísticos en China y España</div>
            <div class="CenterOptCon">
              Contamos con centros logísticos que garantizan la total seguridad
              de las cargas de mercancía. En ellos, nuestro experto equipo lleva
              a cabo tareas diarias de picking de mercancías, almacenamiento,
              manipulación, controles de calidad, etc.
            </div>
            <div class="CenterOptCon">
              Esta área de actividad de Rakumart es una gran ventaja ya que
              estamos haciendo un excelente trabajo cubriendo y administrando la
              cadena de suministro de nuestros clientes.
            </div>
          </div>
          <div class="logisticsCenterOpt">
            <img
              src="../../assets/newImg/footlist/yunshu/rakumart-costes-envio.jpg"
              class="CenterOptImg"
              alt=""
            />
          </div>
        </div>
      </div>

      <!-- 物流服务 -->
      <div class="moudlebackGround">
        <div class="logisticsService pageMoudle">
          <div class="Servicebiaoti">
            <span>Los servicios logísticos incluyen:</span>
            <div class="xian"></div>
          </div>
          <div class="ServiceAll">
            <div class="ServiceOpt">
              <img
                src="../../assets/newImg/footlist/yunshu/WechatIMG67.png"
                alt=""
              />
              <span>Recepción de la carga desde fábrica</span>
            </div>
            <div class="ServiceOpt">
              <img
                src="../../assets/newImg/footlist/yunshu/WechatIMG60.png"
                alt=""
              />
              <span>Almacenamiento de las mercancías</span>
            </div>
            <div class="ServiceOpt">
              <img
                src="../../assets/newImg/footlist/yunshu/WechatIMG66.png"
                alt=""
              />
              <span>Manipulación de mercancías</span>
            </div>
            <div class="ServiceOpt">
              <img
                src="../../assets/newImg/footlist/yunshu/WechatIMG62.png"
                alt=""
              />
              <span>Control de calidad</span>
            </div>
            <div class="ServiceOpt">
              <img
                src="../../assets/newImg/footlist/yunshu/WechatIMG61.png"
                alt=""
              />
              <span>Producción de certificados de fabricación</span>
            </div>
            <div class="ServiceOpt">
              <img
                src="../../assets/newImg/footlist/yunshu/WechatIMG63.png"
                alt=""
              />
              <span>Despacho de aduana</span>
            </div>
            <div class="ServiceOpt">
              <img
                src="../../assets/newImg/footlist/yunshu/WechatIMG68.png"
                alt=""
              />
              <span>Otros servicios bajo demanda</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 运费明细 -->
      <div class="moudlebackGround">
        <div class="shippingDetails pageMoudle">
          <div class="shippingbiaoti">
            <span>Información sobre flete y envíos:</span>
            <div class="xian"></div>
          </div>
          <div class="liebiao">
            <div class="tit" style="margin-top: -4.5px">
              Desglose de costes de envío
            </div>
            <div class="tit2" style="margin-top: -4.5px">
              Envío internacional
            </div>
            <div class="conp" style="margin-top: -4.5px">
              Coste del envío internacional a España.
            </div>
            <div class="tit2" style="margin-top: -8.5px">
              Logística en España
            </div>
            <div class="conp" style="margin-top: -4.5px">
              Coste del picking y envío nacional desde nuestro alamacén en
              España a la dirección de entrega final.
            </div>
            <div class="tit2" style="margin-top: -8.5px">
              Aranceles antidumping
            </div>
            <div class="conp" style="margin-top: -4.5px">
              Pago de los aranceles aduaneros de la mercancía en el estado
              español.
            </div>
            <div class="tit2" style="margin-top: -8.5px">IVA</div>
            <div class="conp" style="margin-top: -4.5px">Impuesto del 21%</div>
            <div class="endXian"></div>
            <div class="tit">Transporte de Mercancías Peligrosas</div>
            <div class="conp">
              Mercancías peligrosas son todos los objetos con características y
              propiedades que pueden: amenazar la vida y la salud humana; causar
              daños irreparables al medio ambiente; causar daños a los objetos
              materiales.
            </div>
            <div class="conp">
              Las mercancías bajo esta denominación son: Animales y productos
              animales, productos vegetales, grasas y aceites animales o
              vegetales, preparados alimenticios, metales comunes, instrumentos
              de precisión, armas y munición, obras de arte.
            </div>
            <div class="endXian"></div>
            <div class="tit">
              Selección de empresas de transporte internacional
            </div>
            <div class="conp">
              Rakumart elegirá canales logísticos internacionales fiables,
              rápidos y económicamente competentes para el envío de tus
              mercancías.
            </div>
            <div class="conp">
              Después de una evaluación cuidadosa, consideramos factores como el
              costo del flete, la puntualidad del transporte y la estabilidad
              del servicio.
            </div>
            <div class="conp">
              Siempre continuamos en constante actualización, seleccionando los
              mejores partners logísticos y rutas de transporte para ofrecerte
              en nuestra plataforma.
            </div>
            <div class="endXian"></div>
            <div class="tit">Despacho de las mercancías en aduanas</div>
            <div class="conp">
              El despacho de aduana de las mercancías se determinará de acuerdo
              con el método de despacho de aduana que elija cuando solicite la
              entrega (marítimo, aéreo o terrestre).
            </div>
            <div class="conp">
              Nuestra empresa se encargará de los asuntos de despacho de aduana
              relevantes. Nuestros clientes también pueden elegir llevar a cabo
              los tramites aduaneros por su cuenta si así lo desean.
            </div>
            <div class="endXian"></div>
          </div>
        </div>
      </div>

      <!-- 专家代理 -->
      <div class="moudlebackGround">
        <div class="expertAgent pageMoudle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            style="enable-background: new 0 0 24 24"
            xml:space="preserve"
          >
            <path
              class="st0"
              d="M11.4,1C6.6,1.3,3,5.5,3,10.3V17c0,1.7,1.3,3,3,3h1c1.1,0,2-0.9,2-2v-4c0-1.1-0.9-2-2-2H5v-1.7 C5,6.4,8,3.1,11.8,3c4-0.1,7.2,3.1,7.2,7v2h-2c-1.1,0-2,0.9-2,2v4c0,1.1,0.9,2,2,2h2v1h-6c-0.6,0-1,0.5-1,1s0.4,1,1,1h5 c1.7,0,3-1.3,3-3V10C21,4.8,16.6,0.7,11.4,1z"
            ></path>
          </svg>
          <div class="expertAgentCon">
            <h1>Contacta con un agente experto</h1>
            <p>
              ¿Estás buscando la mejor solución para tus importaciones desde
              China? Rakumart es la respuesta, nosotros nos encargamos de todo,
              desde la compra del producto hasta la recepción en la dirección de
              entrega. Contacta con un agente experto y empieza a beneficiarte
              de todas nuestras ventajas.
            </p>
            <button @click="openChat">Contacta con un agente</button>
          </div>
        </div>
      </div>
    </div>
    <FootVue></FootVue>
  </div>
</template>
<script>
import FootVue from "../../components/foot/Foot.vue";
import HomePageTopVue from "../../components/head/HomePageTop.vue";
export default {
  data() {
    return {};
  },
  components: { HomePageTopVue, FootVue },
  computed: {
    evn() {
      // || location.origin == 'http://localhost:8080'
      if (
        location.origin == "https://www.rakumart.es" ||
        location.origin == "https://rakumart.eu" ||
        location.origin == "www.rakumart.es" ||
        location.origin == "http://www.rakumart.es"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {},
  methods: {
    openChat() {
      this.$fun.clickJs();
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "../../css/mixin.scss";
.st0 {
  fill: #94aff6;
}
.moudlebackGround {
  min-width: 1400px;
  background-color: white;
  display: flex;
  justify-content: center;
}
.LogisticsCon {
  margin-top: -18px;
  .pageMoudle {
    width: 1200px;
    margin: 0 auto;
  }
  .logoImg {
    height: 456px;
    position: relative;
    background-image: url("../../assets/foot-img/rakumart-1-scaled.jpg");
    background-size: 100% auto;
    background-position: 50% 50%;
    .base-w {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;

      align-items: center;
    }
    .logoInfoTxtBox {
      color: white;

      h1 {
        color: #ffad00;
        font-size: 12px;
        letter-spacing: 2px;
      }
      h2 {
        line-height: 13px;
        font-size: 26px;
        font-weight: 600;

        margin: 40px 0 30px;
      }
      h3 {
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
      }
    }
  }
  .lianXiWoMen {
    height: 487px;
    display: flex;
    justify-content: center;
    align-items: center;
    .lianXibox {
      h1 {
        color: #3c4043;
        font-family: "Open Sans", Sans-serif;
        font-size: 21px;
        font-weight: 500;
        line-height: 1.5em;
        text-align: center;
        height: 31px;
        margin-bottom: 10px;
      }
      .lianXiboxCon {
        width: 500px;
        background-color: #ffffff;
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #e3e3e3;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        transition: background 0.3s, border 0.3s, border-radius 0.3s,
          box-shadow 0.3s;
        padding: 30px 20px 25px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .boxIcon {
          width: 45.8px;
          height: 45.8px;
          margin-bottom: 6px;
        }
        h2 {
          margin-bottom: 5px;
          color: #404040;
          font-family: "Open Sans", Sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: 1.6em;
          margin-bottom: 5px;
        }
        p {
          font-family: "Open Sans", Sans-serif;
          font-size: 16px;
          font-weight: 400;
          width: 437px;
          line-height: 1.8em;
          margin-bottom: 20px;
        }
        button {
          fill: #1a73e8;
          color: #1a73e8;
          background-color: #ffffff00;
          border-style: solid;
          border-width: 1px 1px 1px 1px;
          border-color: #cecece;
          border-radius: 4px 4px 4px 4px;
          padding: 12px 30px 12px 30px;
          display: inline-block;
          line-height: 1;
          font-size: 15px;
          padding: 12px 24px;
          border-radius: 3px;
          font-weight: 600;
          &:hover {
            color: #1a73e8;
            background-color: #f3f7fc;
            border-color: #1a73e8;
          }
        }
      }
    }
  }
  .logisticsProcess {
    padding-bottom: 10px;
    margin-bottom: 80px;
    .AdvantagesTitle {
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      span {
        display: flex;
        padding-left: 12px;
        align-items: center;
        width: 224px;
        font-size: 24px;
        font-weight: 400;
        line-height: 1.5em;
      }
      .xian {
        flex: 1;
        height: 1px;
        background-color: #b4b4b4;
        margin-top: 10px;
      }
    }
    .logisticsProcessOptImg {
      width: 1120px;
      height: 95px;
      margin: 0 auto;
      display: block;
    }
    .logisticsProcessOPtArr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1180px;
      margin: 0 auto;
      .logisticsProcessOPt {
        flex: 1;
        padding: 10px 0px 24.4px;
        line-height: 1.5;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
      }
    }
  }

  .ourAdvantages {
    padding: 80px 0;
    .AdvantagesTitle {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      span {
        display: flex;
        padding-left: 12px;
        align-items: center;
        width: 269px;
        font-size: 24px;
        font-weight: 400;
        line-height: 1.5em;
      }
      .xian {
        flex: 1;
        height: 1px;
        background-color: #b4b4b4;
        margin-top: 10px;
      }
    }
    .AdvantagesTagGroup {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .AdvantagesTag {
        width: 400px;
        height: 153.67px;
        display: flex;
        align-items: flex-start;
        padding: 15px;
        .tagLeftIcon {
          width: 43.59px;
          height: 43.59px;
          margin-right: 15px;
        }
        .tagRightCon {
          .tagRightConTitle {
            margin-top: 8px;
            margin-bottom: 15px;
            font-size: 18px;
            font-weight: 500;
            line-height: 0.8em;
          }
          .tagRightConTBody {
            font-size: 16px;
            font-weight: 400;
            line-height: 1.8em;
          }
        }
      }
    }
  }
  .companyLabor {
    position: relative;
    display: flex;
    margin-bottom: 80px;
    .LaborBackImg {
      width: 976px;
      margin-left: auto;
      height: 100%;
    }
    .LaborFontBox {
      position: absolute;
      left: 0;
      top: 100px;
      width: 553.5px;
      height: 530px;
      .LaborLinkFont {
        color: #1a289d;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.2em;
        letter-spacing: 2px;
        margin-bottom: 34px;
      }
      .LaborFontTitle {
        font-family: "Open Sans", Sans-serif;
        font-size: 21px;
        font-weight: 400;
        line-height: 1.5em;
        margin-bottom: 30px;
      }
      .LaborFontCon {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.8em;
        margin-bottom: 14.4px;
      }
    }
  }
  // 物流方式表格
  .modeOfTransportationTable {
    margin-bottom: 80px;
    .modeOfTransportation {
    }
    .modeOfTransportation > .tul {
      display: flex;
    }
    .modeOfTransportation > .tul.tfoot {
      min-height: 60px;
      display: flex;
      align-items: center;
      border: solid 1px #ebeef5;
      padding: 10px;
      .el-button {
        margin-right: 15px;
      }
    }
    // 表头
    .modeOfTransportation > .tul.ttt {
      background-color: #f0f3f6;
      border-radius: 6px;
      overflow: hidden;
    }
    // 内容
    .modeOfTransportation > .tul.tbo {
      &:nth-child(2n + 1) {
        background-color: #f9f9fb;

        border: 1px solid #f0f3f6;
      }
    }
    // 单个单元格
    .modeOfTransportation > .tul .tli {
      flex: 1;
      // border: #ebeef5 solid 1px;
    }
    // 单个单元格
    .modeOfTransportation .inBox {
      width: 1140px;
      display: flex;
      margin: 0 auto;
      // border: #ebeef5 solid 1px;
    }
    // 所有单个单元格表头
    .modeOfTransportation > .tul .tli.ttt {
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 53px;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 2px;
      color: #7d7f8f;
    }
    // 所有单个单元格内容
    .modeOfTransportation > .tul .tli.tbo {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
    }
    // 标题盒子
    .modeOfTransportation > .tul .tli.tbo.titleBox {
      font-size: 16px;
      font-weight: 600;
    }
    // 内容盒子
    .modeOfTransportation > .tul .tli.tbo.conBox {
      color: #808191;
      font-size: 16px;
      font-weight: 400;
      line-height: 12px;
      height: 66px;
    }
  }
  .logisticsMethodDescription {
    margin-top: 30px;
    padding: 0 30px;
    margin-bottom: 14px;
    line-height: 1.8;
    font-size: 15px;
    color: #808191;
    a {
      color: #1a289d;
    }
  }
  .logisticsYouShi {
    display: flex;
    justify-content: center;
    // margin-bottom: 50px;
    .logisticsYouShiLeft {
      width: 50%;
      height: 566px;
    }
    .logisticsYouShiRight {
      width: 50%;
      background-color: #1c2237;
      color: #ffffff;
      padding: 00px 0 0 70px;
      display: flex;
      align-items: center;
      .YouShiRightTitle {
        font-size: 21px;
        font-weight: 500;
        line-height: 1.5em;
        margin-bottom: 30px;
      }
      .YouShiRightCon {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        padding: 7.5px;
        &:before {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 17px;
          height: 17px;
          margin-right: 5px;
          border-radius: 50%;
          background-color: #fcbd65;
          color: #1c2237;
          font-size: 12px;
          content: "✔";
        }
      }
    }
  }

  .logisticsCenter {
    display: flex;
    padding-bottom: 80px;
    padding-top: 80px;
    .logisticsCenterOpt {
      height: 430px;
      padding: 15px;
      flex: 1;
      display: flex;
      justify-content: center;

      flex-direction: column;

      .CenterOptTt {
        font-size: 21px;
        font-weight: 400;
        line-height: 1.5em;
        margin-bottom: 30px;
      }
      .CenterOptCon {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.8em;
        margin-bottom: 15px;
      }
      .CenterOptImg {
        height: 400px;
        border-radius: 10px;
      }
    }
  }
  .logisticsService {
    padding-bottom: 80px;
    .Servicebiaoti {
      display: flex;
      align-items: center;

      span {
        display: flex;
        padding-left: 12px;
        align-items: center;
        width: 442.95px;
        font-size: 24px;
        font-weight: 400;
        line-height: 65px;
      }
      .xian {
        flex: 1;
        height: 1px;
        background-color: #b4b4b4;
        margin-top: 10px;
      }
    }
    .ServiceAll {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .ServiceOpt {
        width: 400px;
        height: 81px;
        padding: 10px;
        display: flex;
        align-items: center;
        margin-top: 20px;
        padding-bottom: 16px;
        img {
          width: 55.67px;
          height: 55.67px;
          margin-right: 15px;
        }
        span {
          font-size: 17px;
          font-weight: 400;
          line-height: 30.59px;
          margin-bottom: 15px;
        }
      }
    }
  }
  .shippingDetails {
    .shippingbiaoti {
      display: flex;
      align-items: center;

      span {
        display: flex;
        padding-left: 12px;
        align-items: center;
        width: 442.95px;
        font-size: 24px;
        font-weight: 400;
        line-height: 65px;
      }
      .xian {
        flex: 1;
        height: 1px;
        background-color: #b4b4b4;
        margin-top: 10px;
      }
    }
    .liebiao {
      margin-top: 20px;
      margin-left: auto;
      width: 785px;
      padding: 20px;
      .tit {
        color: #1a73e8;
        font-family: "Open Sans", Sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 20px;
      }
      .tit2 {
        font-family: "Open Sans", Sans-serif;
        font-size: 15px;
        font-weight: 600;
        line-height: 1.8em;
        margin-bottom: 10px;
      }
      .conp {
        color: var(--e-global-color-1792e67);
        font-family: "Open Sans", Sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.8em;
        padding-bottom: 14.4px;
      }
      .endXian {
        background-color: #e3e3e3;
        box-sizing: content-box;
        border: solid 2px white;
        border-left: none;
        border-right: none;
        height: 1px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
  .expertAgent {
    height: 451.08px;
    background-color: #f8f9fa;
    margin-bottom: 80px;
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: 50px;
    padding: 70px 150px 70px 150px;
    svg {
      width: 40px;
      height: 40px;
      display: block;
      margin: 0 auto;
    }
    .expertAgentCon {
      width: 658px;
      padding: 10px;
      margin: 0 auto;
      > * {
        text-align: center;
      }
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      h1 {
        font-size: 21px;
        font-weight: 500;
        line-height: 1.5em;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.8em;
        margin-bottom: 34px;
      }
      button {
        font-size: 15px;
        line-height: 15px;
        font-weight: 600;
        padding: 20px 80px 20px 80px;
        border-radius: 4px;
        border: #1a73e8 solid 2px;
        color: #1a73e8;
        &:hover {
          color: #ffffff;
          background-color: #1a73e8;
          border-color: #1a73e8;
        }
      }
    }
  }
}
</style>
