import { render, staticRenderFns } from "./servicios-adicionales.vue?vue&type=template&id=f385dbc0&scoped=true&"
import script from "./servicios-adicionales.vue?vue&type=script&lang=js&"
export * from "./servicios-adicionales.vue?vue&type=script&lang=js&"
import style0 from "./servicios-adicionales.vue?vue&type=style&index=0&id=f385dbc0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f385dbc0",
  null
  
)

export default component.exports